import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mspot-banner-base-style',
  standalone: true,
  imports: [],
  templateUrl: './banner-base-style.component.html',
  host: {
    class:
      'rounded-lg backdrop-blur flex-col border-px sm:flex-row px-3 sm:px-6 py-4 text-sm shadow-md sm:text-sm flex gap-6 sm:gap-4 sm:items-center z-50',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerBaseStyleComponent {}
